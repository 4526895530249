import React, { Suspense, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Parallax } from 'react-scroll-parallax';
import { isMobile } from '../../../../layout/Layout';
import GLightbox from 'glightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const Flickity = React.lazy(() => import('react-flickity-component'));

const TestimonialsSection = () => {
  const mobile = isMobile();

  const options = {
    pageDots: true,
    autoPlay: 1500,
    initialIndex: 0,
    prevNextButtons: isMobile() ? false : true,
    cellAlign: 'right',
    wrapAround: true,
    reloadOnUpdate: true,
  };

  const testimonialDesktopUrls = [
    'https://player.vimeo.com/video/475925292',
    'https://player.vimeo.com/video/475925347',
    'https://player.vimeo.com/video/475925388',
  ];
  const testimonialMobileUrls = [
    'https://player.vimeo.com/video/475925303',
    'https://player.vimeo.com/video/475925386',
    'https://player.vimeo.com/video/475925393',
    'https://player.vimeo.com/video/476346522',
  ];

  const getDesktopTestimonialLightboxes = (testimonials) => {
    return testimonials.map((url) => {
      let lightbox = GLightbox({
        // selector: 'glightbox-demo',
        plyr: {
          css: 'https://cdn.plyr.io/3.6.2/plyr.css', // Default not required to include
          js: 'https://cdn.plyr.io/3.6.2/plyr.js', // Default not required to include
          config: {
            ratio: '16:9', // or '4:3'
            hideControls: false,
            controls: true,
            muted: true,
            playsinline: true,
            vimeo: {
              controls: true,
              byline: false,
              portrait: false,
              title: false,
              speed: true,
              transparent: false,
              autoplay: true,
              playsinline: true,
              dnt: true,
            },
          },
        },
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
        hideControls: false,
        playsinline: true,
        onOpen: () => {
          console.log('Lightbox opened');
        },
        afterSlideLoad: (slide) => {
          let player = slide.player;
          if (player) {
            console.log(slide);
            player.on('ready', (event) => {
              setTimeout(() => {
                player.play();
              }, 1000);
              const instance = event.detail.plyr;
              instance.toggleControls(false);
            });
          }
        },
        elements: [
          {
            href: url,
            type: 'video',
            source: 'vimeo', //vimeo, youtube or local
            width: 900,
          },
        ],
      });
      return lightbox;
    });
  };
  const getMobileTestimonialLightboxes = (testimonials) => {
    return testimonials.map((url) => {
      let lightbox = GLightbox({
        // selector: 'glightbox-demo',
        plyr: {
          css: 'https://cdn.plyr.io/3.6.2/plyr.css', // Default not required to include
          js: 'https://cdn.plyr.io/3.6.2/plyr.js', // Default not required to include
          config: {
            ratio: '9:16', // or '4:3'
            hideControls: false,
            controls: true,
            muted: true,
            playsinline: true,
            vimeo: {
              controls: true,
              byline: false,
              portrait: false,
              title: false,
              speed: true,
              transparent: false,
              autoplay: true,
              playsinline: true,
              dnt: true,
            },
          },
        },
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
        hideControls: false,
        playsinline: true,
        onOpen: () => {
          console.log('Lightbox opened');
        },
        afterSlideLoad: (slide) => {
          let player = slide.player;
          if (player) {
            console.log(slide);
            player.on('ready', (event) => {
              setTimeout(() => {
                player.stop();
              }, 1000);
              const instance = event.detail.plyr;
              instance.toggleControls(false);
            });
          }
        },
        elements: [
          {
            href: url,
            type: 'video',
            source: 'vimeo', //vimeo, youtube or local
            width: 900,
          },
        ],
      });
      return lightbox;
    });
  };

  const testimonialsLightboxesDesktop = getDesktopTestimonialLightboxes(
    testimonialDesktopUrls
  );
  const testimonialsLightboxesMobile = getMobileTestimonialLightboxes(
    testimonialMobileUrls
  );

  return (
    <Row
      className="testimonialsSectionRow h-100 justify-content-center align-items-center"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <Col xs={12}>
        <h1 className="testimoniosSectionTitle">
          <p className="h2Title">
            Twerk <span className="boldText">VIP Club</span>
          </p>
        </h1>
      </Col>
      <Col xs={12} lg={12} className="testimonialsText">
        <Parallax
          className="custom-class"
          // x={[-20, 0]}
          tagOuter="figure"
          disabled={mobile}
        >
          <Suspense fallback={<div></div>}>
            <Flickity
              className="mb-5"
              elementType={'div'} // default 'div'
              options={options} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={true} // default false
              static={true} // default false
            >
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesDesktop[0].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test1-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test1-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesMobile[0].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test2-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test2-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesDesktop[1].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test3-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test3-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesDesktop[2].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test4-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test4-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesMobile[1].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test5-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test5-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesMobile[2].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    <img
                      src={require('../../../../assets/img/photos/testimonials/test6-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  testimonialsLightboxesMobile[3].open();
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    <img
                      src={require('../../../../assets/img/photos/testimonials/test7-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
            </Flickity>
          </Suspense>
        </Parallax>
      </Col>
    </Row>
  );
};

export default TestimonialsSection;
