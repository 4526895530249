import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import './PricesSection.scss';

export const PricesSection = (props) => {
  const { discount } = props;

  return (
    <div className="pricesContainer cardPrices" id="inscribirse">
      <div className="container paymentContainer">
        <div className="row align-items-center">
          <div className="col-12 text-center pricesCol">
            <h1
              className="pricesSectionTitle"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <p className="h2Title">
                Selecciona tu
                <br />
                <span className="boldText">método de pago</span>
              </p>
            </h1>
            <CardDeck data-aos="fade-up" data-aos-duration="1000">
              <Card
                className="cardStyle d-md-flex"
                // data-aos="fade-up"
                // data-aos-duration="1000"
              >
                <Card.Body className="d-md-block bodyCardPrices">
                  <Card.Text className="iconPricesTop">
                    <span>
                      <span className="h3Title">Tarjeta de Crédito</span>
                      <span className="paymentTypeTitle">PAGO ÚNICO (eur)</span>
                    </span>
                  </Card.Text>
                  <div className="pricesTexts">
                    <Card.Text className="beforePrice">
                      <span className="striked-price">149€</span>
                    </Card.Text>

                    <Card.Text className="afterPrice">
                      {discount.price_eur}€
                    </Card.Text>
                    <span className="after pText">AHORA</span>
                  </div>
                  <Card.Text className="iconPricesBottom">
                    <a
                      href={discount.eur_url}
                      className="buttonsPrices buttonImage"
                    >
                      <span>PAGAR</span>
                    </a>
                  </Card.Text>
                </Card.Body>
                <div className="topLine"></div>
                <div className="rightLine"></div>
                <div className="leftLine"></div>
                <div className="bottomLine"></div>
              </Card>
              <Card
                className="cardStyle d-md-flex"
                // data-aos="fade-up"
                // data-aos-duration="1000"
              >
                <Card.Body className="d-md-block bodyCardPrices">
                  <Card.Text className="iconPricesTop">
                    <span>
                      <span className="h3Title">PAYPAL</span>
                      <span className="paymentTypeTitle">Pago único (usd)</span>
                    </span>
                  </Card.Text>
                  <div className="pricesTexts">
                    <Card.Text className="beforePrice">
                      <span className="striked-price">$160</span>
                    </Card.Text>

                    <Card.Text className="afterPrice">
                      ${discount.price_usd}
                    </Card.Text>
                    <span className="after pText">AHORA</span>
                  </div>
                  <Card.Text className="iconPricesBottom">
                    <a
                      href={discount.usd_url}
                      className="buttonsPrices buttonImage"
                    >
                      <span>PAGAR</span>
                    </a>
                  </Card.Text>
                </Card.Body>
                <div className="topLine"></div>
                <div className="rightLine"></div>
                <div className="leftLine"></div>
                <div className="bottomLine"></div>
              </Card>
            </CardDeck>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricesSection;
